<template>


  <div class="row">
    <h1>Request for Proposal</h1>

    <h4>Tamil Language Development Proposal</h4>


    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">

      <p>We, the Tamil Crypto organization, are calling all Tamil language enthusiasts to participate in the development and enrichment of the Tamil language.</p>

      <p>This initiative may include teaching Tamil to diaspora children, organizing Tamil language and culture-related competitions, conducting Tamil language enrichment classes, and forming Tamil culture meetup groups.</p>

      <p>We invite the general public to submit their proposals in PDF format. Each proposal should include the following standard items:</p>

      <ol>
        <li>Introduction: Brief overview of the proposed project.</li>
        <li>Objectives: Clear goals and objectives of the project.</li>
        <li>Activities: Detailed description of planned activities.</li>
        <li>Timeline: Proposed timeline for project execution.</li>
        <li>Budget: Itemized budget outlining funding requirements.</li>
        <li>Implementation Plan: Strategy for implementing the project.</li>
        <li>Evaluation: Criteria for evaluating the success of the project.</li>
        <li>Team: Information about the individuals or organizations involved in the project.</li>
        <li>Sustainability: Plans for sustaining the project beyond its initial phase.</li>
        <li>Contact Information: Contact details for further inquiries.</li>
      </ol>

      <p>Please note that all proposals will be submitted for open voting by holders of Tamil Crypto tokens. Once we gather enough votes, the governance committee will disperse funding to the wallet address mentioned in the proposal.</p>


      <router-link class="btn btn-warning" to="/signup">
        <i class="fa fa-user"></i> Login
      </router-link>
    </div>
    <!-- div container end -->

    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <img
        src="@/assets/images/rfc.jpg"
        alt="Request for Proposal"
        style="width: 100%"
      />
  
    </div>
  </div>
  <!-- row -->
</template>

<script>
export default {};
</script>

<style scoped>
.no-icon li {
  padding: 5px;
  list-style-type: none;
  border-bottom: 1px solid rgb(117, 110, 110);
}
</style>